<template>
  <div>
    <div class="productDiv">
      <p class="cardTitle">方案模式</p>
      <p class="cardSecondTitle" >
        UP灵活用工平台搭建了多方共赢的桥梁，四流合一，确保税务合规
      </p>
      <img src=" /img/solutionBg.png" alt="方案"/>
    </div>
    <div class="case">
      <p class="cardTitle">客户案例</p>
      <div class="caseDiv">
        <div class="caseMenu">
          <el-menu
            default-active="2"
            default-openeds="[{1-1},{2-1},{3-1}]"
            @select="handleSelect"
            class="el-menu-vertical-demo"
          >
            <el-submenu index="1">
              <template slot="title">
                <span>税务合规</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="1-1">兼职案例</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <span>佣金极速到账</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="2-1">影视行业案例</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <span>saas系统定制化</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="3-1">众包企业案例</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
        <div class="caseMenuMobile">
            <ul>
              <li>
                <span>税务合规</span>
                <span @click="chooseCase(1)" :class="caseContentIndex==1? 'hoverClass':''">兼职案例</span>
              </li>
              <li>
                <span>佣金极速到账</span>
                <span @click="chooseCase(2)" :class="caseContentIndex==2? 'hoverClass':''">影视行业案例</span>
              </li>
              <li>
                <span>saas系统定制化</span>
                <span @click="chooseCase(3)" :class="caseContentIndex==3? 'hoverClass':''">众包企业案例</span>
              </li>
            </ul>
        </div>
        <div class="caseContent" v-if="caseContentIndex == 1">
          <p class="casebigTitle">兼职推广人员场景</p>
          <p class="caseConTitle">【业务背景】</p>
          <p class="content">
            市场营销氛围浓厚，业务繁忙需要大批临时兼职人员。由于人员不确定性强、流动性大，兼职佣金结算工作量大，故转向采购灵工服务。
          </p>
          <p class="caseConTitle">【解决方案】</p>
          <p class="content">
            企业采用众包用工模式，将业务外包给UP平台，UP分包给各地的自由职业者(代理)完成，参与活动的导购约有1W+，分布在全国各地。</p>
          <img class="caseImg" src=" /img/case1.png" alt="方案"/>
          <p class="caseConTitle">【方案效果】</p>
          <p class="content">
            通过UP平台解决方案，单次活动精准发放10W+笔，金额300W+，成功率100%，并开具类型为*现代服务*推广服务的专票，为企业解决了财税难题。
          </p>
        </div>

        <div class="caseContent" v-if="caseContentIndex == 2">
          <p class="casebigTitle">影视行业佣金发放极速到账</p>
          <p class="caseConTitle">【业务背景】</p>
          <p class="content">
            某传媒公司由于业务需求经常招募大量群演，但是因为不同剧组需求的变动与临时性，需及时满足不同剧组的人员需求。但碰到的问题是，刚为片场A招募到一批人员满足其需求，又需要根据片场B的需求再次招募一次，或者同时给多个片场进行招募。
          </p>
          <p class="content" style="margin-top: 20px">
            但由于人员众多，片场也多，且一个群演可以同时参演多个片场，若结算佣金时需逐个核对，导致佣金发放滞后，而且每日都会给财务造成很大工作量，经常会出现漏发多发的情况，接到群演的投诉也比较多，对公司对群演都造成很不好的体验。
          </p>
          <img class="caseImg" src=" /img/case2-1.png" alt="方案"/>
          <p class="caseConTitle">【解决方案】</p>
          <p class="content">
            跟UP合作后的解决方案：根据剧组的用人需求在平台发布任务，群演可以通过微信小程序自主接单；当天工作结束后，群演根据要求上传完工证明，传媒公司审核通过后，佣金即时下发到用工人员账户。
          </p>
          <img class="caseImg" src=" /img/case2-2.png" alt="方案"/>
          <p class="caseConTitle">【方案结果】</p>
          <p class="content">
            保证了佣金发放的及时性与准确性，且群演与公司全程线上操作，流程简单，大大提高了工作效率。
          </p>
          <img class="caseImg" src=" /img/case2-3.png" alt="方案"/>
        </div>

        <div class="caseContent" v-if="caseContentIndex == 3">
          <p class="casebigTitle">某众包企业saas系统定制</p>
          <p class="caseConTitle">【业务背景】</p>
          <p class="content">
            某众包企业，其自身拥有落地园区，可以为用工企业解决相关税务问题，从而开展灵活用工相关业务，但是由于企业内部没有搭建共享经济服务平台，因此业务开展迟迟没有进展。
          </p>
          <p class="content" style="margin-top: 20px">
            该企业曾计划从0到1开发一套灵工平台，但由于企业研发团队资源紧张，在技术上很难支持新系统的开发，而如果重新组建技术团队，时间成本与资金成本都是很大一笔消耗，所以在系统的开发实现上较为困难。
          </p>
          <p class="content" style="margin-top: 20px">
            与此同时，该众包企业也一直在寻找合适的第三方灵工平台，期待由第三方平台技术支撑满足企业拥有自身灵活用工平台的需求。经多方咨询与对比，在了解到UP科技平台优势后，该众包企业与UP开展了业务咨询。
          </p>
          <img class="caseImg" src=" /img/case3-1.png" alt="方案"/>
          <p class="caseConTitle">【解决方案】</p>
          <p class="content">
            UP拥有专业的灵工saas系统产品，为众包企业解决缺少灵活用工平台难题。针对众包企业的业务特性，为其定制了一套saas系统，并实现租户数据隔离，确保企业的数据信息安全与保密，实现了该企业拥有自身灵活用工平台的想法。
          </p>
          <p class="caseConTitle">【方案结果】</p>
          <p class="content">
            该众包企业通过UP的解决方案，轻松拥有了一套量身定制的服务平台，短时间内便成功开展业务。
          </p>
          <img class="caseImg" src=" /img/case3-2.png" alt="方案"/>
        </div>
      </div>
    </div>
    <div class="nylx_list">
      <div class="sy_title">
        <h2>核心价值</h2>
        <b></b>
      </div>
      <ul>
        <li>
          <i class="iconfont valueIcon"> &#xe671;</i>
          <h4>合规用工</h4>
          <h3>解决财税难题</h3>
        </li>
        <li>
          <i class="iconfont valueIcon"> &#xe663;</i>
          <h4>提高企业效率</h4>
          <h3>UP一体化处理纳税与申报，减少企业重复性工作</h3>
        </li>
        <li>
          <i class="iconfont valueIcon"> &#xe605;</i>
          <h4>业务场景合规</h4>
          <h3>保障四流合一与完整性</h3>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "solution",
  props: {
    msg: String,
  },
  data() {
    return {
      serverceindex: 0,
      industryindex: 1,
      industryWidth: "240px",
      caseContentIndex: 1,
      menuMode:'vertical',
      industryArr: [
        {
          img: "/img/industry01_s.png",
          imgl: "/img/industry01.png",
          title1: "批发零售",
          title2: "代理商、推广服务等",
          index: 1,
        },
        {
          img: "/img/industry02_s.png",
          imgl: "/img/industry02.png",
          title1: "电商平台",
          title2: "供应链、直播等",
          index: 2,
        },
        {
          img: "/img/industry03_s.png",
          imgl: "/img/industry03.png",
          title1: "物流配送",
          title2: "司机、骑手、装卸服务等",
          index: 3,
        },
        {
          img: "/img/industry04_s.png",
          imgl: "/img/industry04.png",
          title1: "软件开发",
          title2: "技术工程师、信息技术服务等",
          index: 4,
        },
      ],
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if (key == "1-1") {
        this.caseContentIndex = 1;
      } else if (key == "2-1") {
        this.caseContentIndex = 2;
      } else if (key == "3-1") {
        this.caseContentIndex = 3;
      }
    },
    chooseCase(key){
      console.log(key);
        if (key == "1") {
        this.caseContentIndex = 1;
      } else if (key == "2") {
        this.caseContentIndex = 2;
      } else if (key == "3") {
        this.caseContentIndex = 3;
      }
    }
  },
};
</script>

<style scoped>

  .hoverClass{
    font-weight: bold;
    color: #3eb2ff;
  }
.productDiv {
  width: 100%;
  padding: 1px 0 55px 0;
}
.productDiv img {
  margin: 75px auto 0 auto;;
  display: block;
}

.case {
  width: 100%;
  background: #f5f5f5;
  padding: 1px 0 20px 0;
}
.caseDiv {
  width: 1170px;
  margin: 0 auto 30px auto;
  display: flex;
}
.caseMenu {
  box-sizing: border-box;
  border: 0px solid red;
  width: 200px;
  background: #f0f0f0;
}
::v-deep .el-menu {
  padding: 0;
}
::v-deep .el-submenu__title {
  padding: 0;
  height: 50px;
  background: #3eb2ff;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
}
::v-deep .el-submenu .el-menu-item {
  height: 60px;
  line-height: 60px;
  background: #f0f0f0;
  text-align: center;
}
::v-deep .el-menu-item-group__title {
  padding-bottom: 0;
  padding-top: 0;
}
::v-deep .el-menu-item.is-active,
::v-deep .el-menu-item:hover {
  color: #3eb2ff;
}
.caseContent {
  box-sizing: border-box;
  margin-left: 30px;
  flex: 1;
  background: #fff;
  padding: 40px;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 5%);
}
.caseContent .content {
  text-indent: 24px;
  line-height: 30px;
}
.casebigTitle {
  font-size: 29px;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
  font-weight: bold;
}

.caseConTitle {
  line-height: 60px;
  height: 60px;
  color: #333;
  font-size: 18px;
  font-weight: bold;
}
.caseImg {
  margin: 30px auto 10px auto;
  display: inherit;
}
.caseAttention {
  font-weight: bold;
  text-indent: 24px;
  line-height: 30px;
}
.caseAttention:first-child {
  margin-top: 25px;
}
.caseAttention:last-child {
  margin-bottom: 17px;
}
.caseAttention i {
  padding: 0 10px;
}
.red {
  color: red;
  font-style: normal;
}
.el-steps {
  margin: 20px 40px;
}

.nylx_list ul {
  overflow: hidden;
  width: 1200px;
  margin: 0 auto;
}
.nylx_list li {
  width: 400px;
  float: left;
  text-align: center;
  padding-top: 20px;
  /* background: url(../images/d.jpg) repeat-y right; */
}
.nylx_list li img {
  display: block;
  width: 80px;
  margin: 0 auto 10px auto;
  height: 80px;
  /* vertical-align: middle; */
}
.nylx_list li h4 {
  font-size: 18px;
  line-height: 50px;
  height: auto;
  color: #333;
}
.nylx_list li h3 {
  line-height: 30px;
  font-size: 16px;
  padding: 0 50px;
  color: #999;
}
.valueIcon {
  color: #3eb2ff;
  font-size: 80px;
}

/* 笔记本 电脑  */
@media (min-width: 1031px) {
    .caseMenuMobile {
   display: none;
}
}

/*  pad pro 1024(pc一样)*/
@media (min-width: 770px) and (max-width: 1030px) {
  .productDiv img {
    width: 85%;
    margin: 50px auto 0 auto;
  }
  .caseDiv {
    width: 90%;
  }
  .caseContent {
    font-size: 14px;
  }
  .caseMenu {
    width: 200px;
  }
  .caseMenuMobile {
   display: none;
}
  .caseImg {
    width: 90%;
  }
  .nylx_list ul {
    width: 100%;
    display: flex;
  }
  .nylx_list li {
    flex: 1;
  }
}

/* 超小屏幕（手机，小于 768px） */
@media (max-width: 768px) {

   .productDiv img {
    width: 90%;
    margin-top: 30px;
  }
  .caseDiv {
    width: 100%;
        display: block;
  }
  .caseContent {
    font-size: 14px;
  }
  .caseImg {
    width: 90%;
  }
 .caseMenu {
   display: none;
}
.caseMenuMobile {
   display: block;
   width: 100%;
}
.caseMenuMobile ul{
  display: flex;

}
.caseMenuMobile ul li{
  flex: 1;

}
.caseMenuMobile ul li span:first-child{
  display: block;
  height: 50px;
    background: #3eb2ff;
    font-size: 15px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
      border-right: 1px solid #fff;
}
.caseMenuMobile ul li span:last-child{
 display: block;
  height: 40px;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
}


.caseContent {
  margin-left: 0;
  padding: 30px;
}
.caseContent .content {
  text-indent: 24px;
  line-height: 30px;
}
.casebigTitle {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
  font-weight: bold;
}

.caseConTitle {
  line-height: 45px;
  height: 45px;
  color: #333;
  font-size: 16px;
  font-weight: bold;
}
.caseImg {
  margin: 30px auto 10px auto;
  display: inherit;
}

  .nylx_list ul {
    width: 80%;
    display: block;
  }
  .nylx_list li {
    flex: 1;
    width: 80%;
        margin: 10px auto 20px auto;
    float: unset;
  }
  .nylx_list li h4{
    font-size: 18px;
    line-height: 50px;
    margin: 5px;
}
.nylx_list li h3 {
    line-height: 30px;
    font-size: 16px;
    padding: 0 10px;
}
}
</style>
